<template>
  <v-sheet>
    <div class="message-content">
      <!-- 👉 message inbox area -->
      <div v-if="relatedType != 'customer-remark-notes' && relatedType != 'lead-remark-notes'">
        <v-row >
          <v-col class="col-md-3"></v-col>
          <v-col class="col-md-6 d-flex">
            <!-- <v-autocomplete
							v-if="relatedType != 'meeting-notes'"
							:items="btxPlaceholder"
							item-text="text"
							style="max-width: 320px !important"
							item-value="value"
							placeholder="Select Module"
							depressed
							hide-details
							outlined
							prepend-inner-icon="mdi-tag"
							v-on:change="getNotesRemark()"
							v-model="selected_date"
						></v-autocomplete> -->

            <div class="d-flex ml-5">
              <v-text-field
                right
                ref="search"
                v-model.trim="DataSearch"
                autocomplete="off"
                hide-details
                placeholder="search."
                outlined
                @keydown.esc="onEsc"
                @keydown.enter="getNotesRemark"
                style="max-width: 350px; min-width: 250px"
              >
                <template #prepend-inner>
                  <v-icon color="grey" class="ml-1 mr-2"> mdi-magnify </v-icon>
                </template>
              </v-text-field>
              <v-btn
                depressed
                color="white"
                class="blue darken-4"
                tile
                text
                small
                style="height: 35px"
                v-on:click="getNotesRemark"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
              <v-btn
                depressed
                color="white"
                class="red darken-4 ml-2"
                tile
                text
                small
                style="height: 35px; min-width: 0px !important"
                v-on:click="resetSearch"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </v-col>
          <v-col class="col-md-3"></v-col>
        </v-row>
      </div>
      <div class="inbox">
        <!-- {{ messages }} -->

        <div class="" v-for="(msgGrp, index) in notesMsg" :key="index">
          <div v-if="msgGrp && msgGrp.label">
            <span class="divider-by-day pb-8"
              ><span>{{ msgGrp.label }}</span></span
            >
          </div>

          <div :class="msgGrp.main_class">
            <div class="chat-avatar ms-4" v-if="!msgGrp.is_label">
              <template>
                <v-avatar size="38">
                  <img v-if="msgGrp.user_img" :src="msgGrp.user_img" :alt="msgGrp.name" />
                  <img
                    v-else
                    src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png"
                  />
                </v-avatar>
                <span>{{ msgGrp.name }}</span>
              </template>
              <!-- <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar size="38" v-bind="attrs" v-on="on">
                    <img v-if="msgGrp.user_img" :src="msgGrp.user_img" :alt="msgGrp.name" />
                    <img
                      v-else
                      src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png"
                    />
                  </v-avatar>
                </template>
                <span>{{ msgGrp.name }}</span>
              </v-tooltip> -->
            </div>
            <div class="chat-body d-inline-flex flex-column align-end">
              <div class="text-right">
                <div :class="msgGrp.color">
                  <template v-if="msgGrp && msgGrp.remark">
                    <span style="white-space: pre-wrap" v-html="msgGrp.remark"></span>
                  </template>
                  <template v-else>
                    <template v-if="fileExten(msgGrp.attachment) == 'image'">
                      <v-img
                        :src="msgGrp.attachment"
                        contain
                        class="white lighten-2"
                        :max-height="150"
                        :max-width="150"
                        v-on:click="FileDownload(msgGrp.attachment)"
                      >
                      </v-img>
                      {{ msgGrp.attachment_name }}
                    </template>
                    <template v-if="fileExten(msgGrp.attachment) == 'pdf'">
                      <inline-svg
                        style="height: 50px; width: 50px"
                        :src="$assetURL('media/mime/pdf.svg')"
                        v-on:click="FileDownload(msgGrp.attachment)"
                      />
                      {{ msgGrp.attachment_name }}
                    </template>
                    <template v-if="fileExten(msgGrp.attachment) == 'doc'">
                      <inline-svg
                        style="height: 50px; width: 50px"
                        :src="$assetURL('media/mime/doc.svg')"
                        v-on:click="FileDownload(msgGrp.attachment)"
                      />
                      {{ msgGrp.attachment_name }}
                    </template>
                    <template v-if="fileExten(msgGrp.attachment) == 'excel'">
                      <inline-svg
                        style="height: 50px; width: 50px"
                        :src="$assetURL('media/mime/xls.svg')"
                        v-on:click="FileDownload(msgGrp.attachment)"
                      />
                      {{ msgGrp.attachment_name }}
                    </template>
                  </template>
                  <span class="mark-as-start">
                    <v-menu
                      class="custom-width"
                      bottom
                      left
                      style="min-width: 150px !important; max-width: 150px !important"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" color="grey" class="cursor-pointer"
                          >mdi-dots-vertical</v-icon
                        >
                      </template>
                      <v-list nav dense class="py-0 px-0">
                        <v-list-item-group color="primary">
                          <template v-for="(item, actionIndex) in actionList">
                            <v-list-item
                              v-if="
                                !fileExten(msgGrp.attachment) ||
                                (fileExten(msgGrp.attachment) && item.value == 'delete')
                              "
                              :key="`${actionIndex}-items`"
                              class="ma-0 border-b rounded-0"
                              :value="item.value"
                              style="border-bottom: 1px solid #e5d7d7"
                              v-on:click="doAction(msgGrp, item.value)"
                            >
                              <v-list-item-icon class="ms-0 me-1 px-0">
                                <v-icon
                                  style="color: #2c2b2b !important"
                                  v-text="item.icon"
                                ></v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title style="color: #2c2b2b !important">{{
                                  item.title
                                }}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-list-item-group>
                      </v-list>
                    </v-menu>
                  </span>
                </div>
              </div>
              <template v-if="msgGrp && !msgGrp.is_label">
                <div
                  :class="`d-flex align-items-center justify-space-between ${
                    msgGrp.main_class == 'chat-group d-flex align-start mb-8'
                      ? 'flex-row-reverse'
                      : ''
                  }`"
                >
                  <span
                    v-if="
                      (msgGrp && msgGrp.meeting_barcode) ||
                      msgGrp.task_barcode ||
                      msgGrp.customer_barcode
                    "
                    class="mb-1 mr-2"
                    label
                    color="red white--text"
                    x-small
                    :text="msgGrp.type"
                  >
                    <v-chip
                      class="mb-1 mr-1"
                      label
                      color="red white--text"
                      x-small
                      v-if="msgGrp && msgGrp.meeting_barcode && msgGrp.task_barcode == null"
                    >
                      <span v-if="msgGrp && msgGrp.meeting_barcode">{{
                        msgGrp.meeting_barcode
                      }}</span>
                    </v-chip>
                    <v-chip
                      class="mb-1 mr-1"
                      label
                      color="cyan white--text"
                      x-small
                      v-if="msgGrp && msgGrp.task_barcode && msgGrp.meeting_barcode == null"
                    >
                      <span v-if="msgGrp && msgGrp.task_barcode">{{ msgGrp.task_barcode }}</span>
                    </v-chip>
                    <v-chip
                      class="mb-1 mr-1"
                      label
                      color="blue white--text"
                      x-small
                      v-if="
                        msgGrp &&
                        msgGrp.task_barcode == null &&
                        msgGrp.meeting_barcode == null &&
                        msgGrp.customer_barcode
                      "
                    >
                      <span v-if="msgGrp && msgGrp.customer_barcode">{{
                        msgGrp.customer_barcode
                      }}</span>
                    </v-chip>
                  </span>

                  <span class="text-xs me-1 text-disabled" style="color: #b59e9e"
                    ><span>{{ msgGrp.user_name ? msgGrp.user_name + " - " : "" }}</span>
                    {{ msgGrp.added_at }}</span
                  >
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="textarea custom_suggest" style="background-color: #fff">
        <div class="suggest_list" v-if="menushow">
          <v-card elevation="0" style="border: 1px solid lightgray">
            <v-list>
              <v-list-item-group v-model="selectedItem" color="blue">
                <v-list-item v-for="(row, index) in siggestFilteredList" :key="index">
                  <v-list-item-avatar class="my-1 mr-2" v-on:click="mentionedUser(row)">
                    <v-avatar color="indigo" size="30">
                      <span class="white--text text-h6">{{ row.display_name[0] }}</span>
                    </v-avatar>
                  </v-list-item-avatar>
                  <v-list-item-content v-on:click="mentionedUser(row)">
                    <v-list-item-title style="text-transform: capitalize !important">{{
                      row.display_name
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </div>
        <div class="pb-1">
          <v-icon class="attachment" @click="uploadDialog()">mdi-attachment</v-icon>
        </div>
        <!-- <v-textarea
					ref="textarea"
					style="margin-bottom: -60px !important; min-height: 100px;"
					background-color="#e9e9e9"
					@click:append="onSubmit()"
					solo
					class="ma-0 rounded chat-textarea"
					v-model.trim="notesData.notes_remark"
					rows="4"
					placeholder="Type your message.."
					@keypress="insertLineBreak"
				>
					<template #append>
						<span
							v-if="!notesData.notes_remark"
							style="position: absolute; right: 60px; bottom: 11px; color: grey; font-size: 15px"
							>Quick send:"Enter"/Start a new line:"Shift+enter"</span
						>
						<v-btn
							:disabled="!notesData.notes_remark ? true : false"
							class="send_btn"
							@click="onSubmit()"
							color="blue darken-4 text-white"
							rounded
						>
							Send
						</v-btn>
					</template>
				</v-textarea> -->
        <v-textarea
          autocomplete="name"
          background-color="#EEF0F8"
          @click:append="onSubmit()"
          solo
          no-resize
          :rows="autoGrowSize"
          class="ma-0 mb-3 rounded chat-textarea"
          v-model.trim="notesData.notes_remark"
          placeholder="Type your message.."
          @keypress="insertLineBreak"
          @keyup="checkSearchingData"
          hide-details
        >
          <template #append>
            <span
              :disabled="!notesData.notes_remark ? true : false"
              style="position: absolute; right: 100px; bottom: 14px; color: grey; font-size: 15px"
              >Quick send:"Enter"/Start a new line:"Shift+enter"</span
            >
            <v-btn
              :disabled="!notesData.notes_remark ? true : false"
              class="send_btn"
              @click="onSubmit()"
              color="blue darken-4 text-white"
              rounded
            >
              Send
            </v-btn>
            <v-icon @click="manageSize" class="extend_btn cursor-pointer">mdi-arrow-expand</v-icon>
          </template>
        </v-textarea>
      </div>
    </div>
    <add-dialog
      :document-dialog="openUploadModel"
      v-on:close="openUploadModel = false"
      v-on:success="getNotesRemark()"
      :related-id="relatedId"
      :chat="0"
      :related-type="relatedType"
    ></add-dialog>
  </v-sheet>
</template>

<script>
import { QUERY, POST } from "@/core/services/store/request.module";
import MomentJS from "moment-timezone";
import AddDialog from "./AddDialog";

import { RemarkEventBus } from "@/core/lib/remark.lib";
import { LeadEventBus } from "@/core/lib/leadremark.lib";
import { SET_MESSAGE } from "@/core/services/store/common.module";
import ApiService from "@/core/services/api.service";

import { last, startsWith, toSafeInteger, cloneDeep } from "lodash";
export default {
  name: "MessageTab",
  props: {
    relatedId: {
      type: Number,
      default: 0,
    },
    siggestList: {
      type: Array,
      default() {
        return [];
      },
    },
    relatedType: {
      type: String,
      default: null,
    },
    createUrl: {
      type: String,
      default: null,
    },
    defaultRemakr: {
      type: String,
      default: null,
    },
    defaultDate: {
      type: String,
      default: null,
    },
    getUrl: {
      type: String,
      default: null,
    },
    isDialog: {
      type: Boolean,
      default: false,
    },
    relatedUrl: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      /* siggestList: [
				{ id:1, name: "Rajan Chauhan", avatar: "" },
				{ id:9, name: "Raja Ji", avatar: "" },
				{ id:2, name: "Parvej Ali", avatar: "" },
				{ id:3, name: "Neeraj Maury", avatar: "mdi-flag" },
				{ id:6, name: "Mehmood Rajpoot", avatar: "" },
				{ id:7, name: "Shubham", avatar: "" },
				{ id:8, name: "amit", avatar: "mdi-flag" },
			], */
      siggestFilteredList: [],
      selectedUser: [],
      selectedUserIds: [],
      selectedItem: null,
      notes_remark: "",
      menushow: false,
      messages: [
        {
          message: "Hi",
          time: "Mon Dec 10 2018 07:45:00 GMT+0000 (GMT)",
          date: "03/04/2023",
          senderId: 11,
          feedback: {
            isSent: true,
            isDelivered: true,
            isSeen: true,
          },
        },

        {
          message: "Hello. How can I help You?",
          time: "Mon Dec 11 2018 07:45:15 GMT+0000 (GMT)",
          date: "03/04/2023",
          senderId: 2,
          feedback: {
            isSent: true,
            isDelivered: true,
            isSeen: true,
          },
        },
        {
          message:
            "Can I get details of my last transaction I made last month? 🤔 Can I get details of my last transaction I made last month? 🤔 Can I get details of my last transaction I made last month? 🤔",
          time: "Mon Dec 11 2018 07:46:10 GMT+0000 (GMT)",
          date: "10/04/2023",
          senderId: 11,
          feedback: {
            isSent: true,
            isDelivered: true,
            isSeen: true,
          },
        },
        {
          message: "We need to check if we can provide you such information.",
          time: "Mon Dec 11 2018 07:45:15 GMT+0000 (GMT)",
          date: "10/04/2023",
          senderId: 2,
          feedback: {
            isSent: true,
            isDelivered: true,
            isSeen: true,
          },
        },
        {
          message: "I will inform you as I get update on this.",
          time: "Mon Dec 11 2018 07:46:15 GMT+0000 (GMT)",
          date: "10/04/2023",
          senderId: 2,
          feedback: {
            isSent: true,
            isDelivered: true,
            isSeen: true,
          },
        },
        {
          message:
            "If it takes long you can mail me at my mail address. Can I get details of my last transaction I made last month? 🤔",
          time: "Mon May 15 2023 13:40:51 GMT+0530 (India Standard Time)",
          date: "10/04/2023",
          senderId: 11,
          feedback: {
            isSent: true,
            isDelivered: false,
            isSeen: false,
          },
        },
        {
          message: "New Mobile kes long image.png",
          file: "http://127.0.0.1:8080/src/assets/images/pages/custom-checkbox-img-2.png",
          time: "Mon May 15 2023 13:40:51 GMT+0530 (India Standard Time)",
          date: "10/04/2023",
          senderId: 2,
          feedback: {
            isSent: true,
            isDelivered: false,
            isSeen: false,
          },
        },
        {
          message:
            "New Mobile kes long image.png Can I get details of my last transaction I made last month? 🤔",
          file: "http://127.0.0.1:8080/src/assets/images/pages/custom-checkbox-img-2.png",
          time: "Mon May 15 2023 13:40:51 GMT+0530 (India Standard Time)",
          date: "10/04/2023",
          senderId: 11,
          feedback: {
            isSent: true,
            isDelivered: false,
            isSeen: false,
          },
        },
        {
          message: "New Doc",
          file: "http://127.0.0.1:8080/src/assets/pdf/dummy.pdf",
          time: "Mon May 15 2023 13:40:51 GMT+0530 (India Standard Time)",
          date: "10/04/2023",
          senderId: 2,
          feedback: {
            isSent: true,
            isDelivered: false,
            isSeen: false,
          },
        },
      ],
      msgGroups: {
        profileUser: {
          id: 11,
          avatar: "",
          fullName: "John Doe",
          role: "admin",
          about:
            "Dessert chocolate cake lemon drops jujubes. Biscuit cupcake ice cream bear claw brownie marshmallow.",
          status: "online",
          settings: {
            isTwoStepAuthVerificationEnabled: true,
            isNotificationsOn: false,
          },
        },
        activeChat: {
          chat: {
            id: 1,
            userId: 2,
            unseenMsgs: 0,
            messages: [
              {
                message: "Hi",
                time: "Mon Dec 10 2018 07:45:00 GMT+0000 (GMT)",
                date: "03/04/2023",
                senderId: 11,
                feedback: {
                  isSent: true,
                  isDelivered: true,
                  isSeen: true,
                },
              },
              {
                message: "How are you?",
                time: "Mon Dec 10 2018 07:45:00 GMT+0000 (GMT)",
                date: "03/04/2023",
                senderId: 11,
                feedback: {
                  isSent: true,
                  isDelivered: true,
                  isSeen: true,
                },
              },
              {
                message: "Hello. How can I help You?",
                time: "Mon Dec 11 2018 07:45:15 GMT+0000 (GMT)",
                date: "03/04/2023",
                senderId: 2,
                feedback: {
                  isSent: true,
                  isDelivered: true,
                  isSeen: true,
                },
              },
              {
                message: "Can I get details of my last transaction I made last month? 🤔",
                time: "Mon Dec 11 2018 07:46:10 GMT+0000 (GMT)",
                date: "10/04/2023",
                senderId: 11,
                feedback: {
                  isSent: true,
                  isDelivered: true,
                  isSeen: true,
                },
              },
              {
                message: "We need to check if we can provide you such information.",
                time: "Mon Dec 11 2018 07:45:15 GMT+0000 (GMT)",
                date: "10/04/2023",
                senderId: 2,
                feedback: {
                  isSent: true,
                  isDelivered: true,
                  isSeen: true,
                },
              },
              {
                message: "I will inform you as I get update on this.",
                time: "Mon Dec 11 2018 07:46:15 GMT+0000 (GMT)",
                date: "10/04/2023",
                senderId: 2,
                feedback: {
                  isSent: true,
                  isDelivered: true,
                  isSeen: true,
                },
              },
              {
                message: "If it takes long you can mail me at my mail address.",
                time: "Mon May 15 2023 13:40:51 GMT+0530 (India Standard Time)",
                date: "10/04/2023",
                senderId: 11,
                feedback: {
                  isSent: true,
                  isDelivered: false,
                  isSeen: false,
                },
              },
              {
                message: "New Mobile kes long image.png",
                file: "http://127.0.0.1:8080/src/assets/images/pages/custom-checkbox-img-2.png",
                time: "Mon May 15 2023 13:40:51 GMT+0530 (India Standard Time)",
                date: "10/04/2023",
                senderId: 2,
                feedback: {
                  isSent: true,
                  isDelivered: false,
                  isSeen: false,
                },
              },
              {
                message: "New Mobile kes long image.png",
                file: "http://127.0.0.1:8080/src/assets/images/pages/custom-checkbox-img-2.png",
                time: "Mon May 15 2023 13:40:51 GMT+0530 (India Standard Time)",
                date: "10/04/2023",
                senderId: 11,
                feedback: {
                  isSent: true,
                  isDelivered: false,
                  isSeen: false,
                },
              },
              {
                message: "New Doc",
                file: "http://127.0.0.1:8080/src/assets/pdf/dummy.pdf",
                time: "Mon May 15 2023 13:40:51 GMT+0530 (India Standard Time)",
                date: "10/04/2023",
                senderId: 2,
                feedback: {
                  isSent: true,
                  isDelivered: false,
                  isSeen: false,
                },
              },
            ],
          },
          contact: {
            id: 2,
            fullName: "Harriet McBride",
            role: "UI/UX Designer",
            about:
              "Toffee caramels jelly-o tart gummi bears cake I love ice cream lollipop. Sweet liquorice croissant candy danish dessert icing. Cake macaroon gingerbread toffee sweet.",
            avatar: "",
            status: "busy",
          },
        },
      },
      actionList: [
        {
          title: "Copy",
          value: "copy",
          icon: "mdi-content-copy",
        },
        {
          title: "Edit",
          value: "edit",
          icon: "mdi-pencil",
        },
        {
          title: "Delete",
          value: "delete",
          icon: "mdi-delete",
        },
        /* {
					title: "Mark As Star",
					value: "mark_as_star",
					icon: "mdi-star",
				},
				{
					title: "Create Job",
					value: "create_job",
					icon: "mdi-plus",
				},
				{
					title: "Create Task",
					value: "create_task",
					icon: "mdi-plus",
				},
				{
					title: "Create Reminder",
					value: "create_reminder",
					icon: "mdi-plus",
				}, */
      ],
      DataSearch: null,
      notesMsg: [],
      selected_date: "all",
      id: 0,
      searchFlag: false,
      focus: false,
      autoGrowSize: 4,
      notesData: {
        notes_remark: null,
        mentioned_user: [],
      },
      singleRemark: null,
      dataLists: [
        { value: "all", text: "All" },
        { value: "lead-notes", text: "Lead" },
        { value: "customer-notes", text: "Customer" },
        { value: "task-notes", text: "Task" },
        { value: "meeting-notes", text: "Meeting" },
      ],
      dataListsCustomer: [
        { value: "all", text: "All" },
        { value: "customer-notes", text: "Customer" },
        { value: "meeting-notes", text: "Meeting" },
      ],
      dataTask: [
        { value: "all", text: "All" },
        { value: "task-notes", text: "Task" },
        { value: "meeting-notes", text: "Meeting" },
      ],
      pageLoading: false,
      currentDate: null,
      openUploadModel: false,
    };
  },

  components: {
    AddDialog,
  },
  methods: {
    fileExten(file) {
      if (
        this.getFileExtension(file) == "jpg" ||
        this.getFileExtension(file) == "jpeg" ||
        this.getFileExtension(file) == "png" ||
        this.getFileExtension(file) == "jfif"
      ) {
        return "image";
      } else if (this.getFileExtension(file) == "pdf") {
        return "pdf";
      } else if (this.getFileExtension(file) == "docx" || this.getFileExtension(file) == "doc") {
        return "doc";
      } else if (this.getFileExtension(file) == "xls" || this.getFileExtension(file) == "xlsx") {
        return "excel";
      }
    },
    manageSize() {
      if (this.autoGrowSize == 4) {
        this.autoGrowSize = 35;
      } else {
        this.autoGrowSize = 4;
      }
    },
    sendData() {
      this.messages.push({
        message: this.notes_remark,
        time: "Mon Dec 10 2018 07:45:00 GMT+0000 (GMT)",
        date: "03/04/2023",
        senderId: 11,
        feedback: {
          isSent: true,
          isDelivered: true,
          isSeen: true,
        },
      });
      this.notes_remark = "";
    },

    doAction(data, type) {
      if (type == "edit") {
        this.editFunction(data);
      }
      if (type == "delete") {
        this.deleteFunction(data);
      }
      if (type == "copy") {
        this.copyNumber(data.remark);
      }
    },
    resizeTextarea() {
      // This method handles the textarea resizing as you type.
      // You can implement it using JavaScript or by adjusting CSS styles.
      // Here, we'll demonstrate using a CSS class to adjust the height.
      const textarea = this.$el.querySelector(".resizable-textarea");
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    },
    editFunction(data) {
      (this.notesData.notes_remark = data.remark), (this.id = data.id);
    },
    /* 	deleteFunction(data){
		 this.notesData.notes_remark = data.remark,
		 this.id = data.id 
		 this.focus = true
		}, */
    deleteFunction(data) {
      if (data && !data.id) {
        return false;
      }
      this.pageLoading = true;
      ApiService.delete(`chat-notes/${this.relatedType}/${this.relatedId}/${data.id}`)
        .then(() => {
          this.pageLoading = false;
          this.$store.commit(SET_MESSAGE, [
            { model: true, message: "Success ! File Deleted successfully." },
            /* this.$emit("success", true), */
          ]);
          this.getNotesRemark();
        })
        .catch((error) => {
          this.$emit("error", error);
          this.logError(error);
        })
        .finally(() => {});
    },
    getFileExtension(path) {
      if (path) {
        return last(path.split("."));
      }
    },
    FileDownload(data) {
      window.open(data, "_blank");
    },

    onEsc() {
      this.resetSearch();
    },
    resetSearch() {
      const _this = this;
      _this.$nextTick(() => {
        this.selected_date = "all";
        this.DataSearch = null;
        this.getNotesRemark();
      });
    },
    copyNumber(data, type) {
      if (type == "phone") {
        data = data.split("-").pop();
      }
      navigator.clipboard.writeText(data);
      this.$store.commit(SET_MESSAGE, [
        { model: true, message: `<b>${data}</b> - successfully coppied.` },
      ]);
    },
    uploadDialog() {
      this.openUploadModel = true;
    },
    mentionedUser(data) {
      this.selectedUser.push("@" + data.display_name);
      this.selectedUserIds.push(toSafeInteger(data.id));
      //let toStr = this.selectedUser.toString()
      let cloneData = cloneDeep(this.notesData.notes_remark);
      this.notesData.notes_remark = `${cloneData}${data.display_name}`;
      this.menushow = false;
    },
    checkSearchingData() {
      if (this.searchFlag) {
        if (this.notesData.notes_remark) {
          let newSearch = this.notesData.notes_remark.split("@").pop();
          let data = this.siggestList.filter((item) =>
            startsWith(item.display_name.toLowerCase(), newSearch.toLowerCase(), 0)
          );
          this.siggestFilteredList = data;
        } else {
          this.menushow = false;
        }
      }
    },
    insertLineBreak(event) {
      if (event.shiftKey && event.key === "@") {
        this.menushow = true;
        this.searchFlag = true;
        if (this.notesData.notes_remark) {
          let newSearch = this.notesData.notes_remark.split("@").pop();
          let data = this.siggestList.filter((item) =>
            startsWith(item.display_name.toLowerCase(), newSearch.toLowerCase(), 0)
          );
          this.siggestFilteredList = data;
        }
      }
      if (event.shiftKey && event.key === "Enter") {
        this.notesData.notes_remark += "\n";
        event.preventDefault();
        return false;
      }
      if (event.key === "Enter" && !event.shiftKey) {
        this.onSubmit();
      }
    },
    onSubmit() {
      if (this.notesData && this.notesData.notes_remark) {
        if (
          this.selectedUserIds &&
          this.selectedUserIds.length &&
          this.selectedUserIds.length > 0
        ) {
          this.notesData.mentioned_user = this.selectedUserIds;
        }
        const _notesData = { ...this.notesData };
        this.notesData.notes_remark = "";
        this.notesData.mentioned_user = [];
        this.pageLoading = true;
        let URl = `${this.createUrl}/${this.relatedType}/${this.relatedId}`;
        console.log(this.createUrl, "URl");
        if (this.id && this.id > 0) {
          URl = `${this.createUrl}/${this.relatedType}/${this.relatedId}/${this.id}`;
        }
        this.$store
          .dispatch(POST, {
            url: URl,
            data: _notesData,
          })
          .then(() => {
            this.focus = false;
            this.id = 0;
            this.notesData.notes_remark = "";
            if (this.defaultRemakr == null && this.relatedUrl) {
              // this.getSingleRemark();
            }
            if (this.relatedType == "customer-notes") {
              RemarkEventBus.$emit("table-refresh", true);
            }
            if (this.relatedType == "lead-notes") {
              LeadEventBus.$emit("table-lead-refresh", true);
            }

            this.getNotesRemark();
            this.$emit("refress", true);

            // this.$refs.form.reset();
            this.scrollBottom();
          })
          .catch((error) => {
            console.log({ error });
          })
          .finally(() => {
            this.pageLoading = false;
          });
      }
    },
    scrollBottom() {
      const nestedElement = document.querySelector(".message-content .inbox");
      this.$nextTick(() => {
        nestedElement.scrollTo(0, nestedElement.scrollHeight);
      });
    },
    getNotesRemark() {
      this.$store
        .dispatch(QUERY, {
          url: `${this.getUrl}/${this.relatedType}/${this.relatedId}`,
          data: {
            search: this.DataSearch || null,
            filter: this.selected_date || null,
          },
        })
        .then((data) => {
          this.notesMsg = data;
        })
        .catch((error) => {
          console.log({
            error,
          });
        });
    },
    getSingleRemark() {
      this.$store
        .dispatch(QUERY, {
          url: `${this.relatedUrl}/${this.relatedId}`,
        })
        .then((data) => {
          if (!this.defaultRemakr) {
            if (this.relatedUrl == "lead") {
              this.defaultRemakr = data.lead.remark;
              this.singleRemark = data.lead.remark;
            } else {
              this.defaultRemakr = data.customer.remark;
              this.singleRemark = data.customer.remark;
            }
          }
        })
        .catch((error) => {
          console.log({
            error,
          });
        });
    },
  },
  computed: {
    btxPlaceholder() {
      if (this.relatedType == "lead-notes") {
        return this.dataLists;
      } else if (this.relatedType == "customer-notes") {
        return this.dataListsCustomer;
      } else if (this.relatedType == "task-notes") {
        return this.dataTask;
      }
      return "";
    },
  },
  mounted() {
    this.getNotesRemark();
    this.currentDate = MomentJS().format("HH:II A");
  },
  watch: {
    notes_remark: function () {
      this.$refs.textarea.style.height = "auto";
      this.$nextTick(() => {
        this.$refs.textarea.style.height = this.$refs.textarea.scrollHeight + "px";
      });
    },
    relatedId() {
      this.getNotesRemark();
    },
  },
};
</script>
<style scoped>
[contenteditable] {
  outline: 0px solid transparent;
}
.v-dialog__content.v-dialog__content--active {
  z-index: 100 !important;
}
.editme {
  width: 100%;
  padding: 1rem;
  border: 1px solid #ccc;
  margin-bottom: 1rem;
}
.wave {
  animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s; /* Change to speed up or slow down */
  animation-iteration-count: infinite; /* Never stop waving :) */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;
  font-size: 38px;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}

/* For demonstration purposes only: */
body {
  font-size: 3.5em;
}
h1 {
  font-size: 0.5em;
}
.message-content .inbox {
  height: calc(100vh - 460px)!important;
  /* height: 100%; */
  overflow: auto;
}
.v-overlay.v-overlay--active.theme--dark {
  z-index: 99 !important;
}
.inbox .mine .message {
  /* background-color: #ffcdb3; */
  background-color: #c7d7f0;
  border-end-end-radius: 26px;
  border-end-start-radius: 26px;
  border-start-start-radius: 26px;
  max-width: 100%;
}
.inbox .chat-left {
  background-color: #fff;
  border-end-end-radius: 26px;
  border-end-start-radius: 26px;
  border-start-end-radius: 26px;
  margin-left: 15px;
}
.inbox .chat-right {
  background-color: #c7d7f0;
  border-end-end-radius: 26px;
  border-end-start-radius: 26px;
  border-start-start-radius: 26px;
  text-align: left;
  /* max-width: 75%;
	margin-right: 0;
	margin-left: auto; */
}
.chat-body {
  max-width: 75% !important;
}
.chat-content {
  position: relative;
}
.chat-right span.mark-as-start {
  right: auto;
  left: -28px;
  top: 12px;
}
.chat-left .mark-as-start {
  position: absolute;
  right: -28px;
  visibility: hidden;
  opacity: 0;
  top: 12px;
}
span.mark-as-start {
  position: absolute;
  right: -28px;
  visibility: hidden;
  opacity: 0;
}
.chat-group:hover span.mark-as-start {
  visibility: visible;
  opacity: 1;
}
.mark-as-start .v-menu__content.theme--light.menuable__content__active {
  min-width: 180px !important;
  max-width: 180px !important;
}
.message-content
  .chat-textarea.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
  > .v-input__control
  > .v-input__slot
  textarea {
  padding-right: 75px !important;
  min-height: 91px !important;
  max-height: 80vh;
  overflow: auto;
}
/* .textarea .send_btn {
	height: 36px !important;
	padding: 0px 20px !important;
	position: absolute;
	bottom: 32px !important;
	right: -10px !important;
	transform: rotate(90deg);
	margin-top: 4px;
	font-size: 16px !important;
} */
.attachment {
  font-size: 30px;
  color: #000;
}
.custom-font-weight {
  font-weight: 600;
}
.custom-font-weight-new {
  font-weight: 100;
}
.textarea ::-webkit-scrollbar {
  width: 1em;
}
/* .custom_suggest {
	position: relative;
} */
.suggest_list {
  position: absolute;
  z-index: 9;
  bottom: 133px;
}
.v-list-item.v-list-item--link.theme--light {
  border-bottom: 1px solid lightgray;
}
.textarea .send_btn {
  height: 36px !important;
  padding: 0px 20px !important;
  position: absolute;
  bottom: 8px !important;
  right: 7px !important;
  /* bottom: 32px !important;
	right: -10px !important; */
  margin-top: 4px;
  font-size: 16px !important;
}
.extend_btn {
  position: absolute;
  top: 6px;
  right: 6px;
}
.inbox .divider-by-day {
  border-top: 1px solid #dfe2e7;
  display: block;
  height: 10px;
  text-align: center;
  margin-top: 28px;
}
.inbox .divider-by-day span {
  background-color: #fff;
  color: #647890;
  display: inline-block;
  line-height: 20px;
  padding: 0 4px;
  position: relative;
  top: -10px;
}
</style>
